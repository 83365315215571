<template>
  <div class="h-screen flex w-full bg-img items-center justify-center" @keyup.enter="resetPassword(email)">
    <div class="flex justify-center sm:w-full lg:w-2/3 xl:w-3/5 xxl:w-2/5 sm:m-0 m-4 self-center">
      <vx-card class="w-full sm:w-1/2">
        <div slot="no-body" class="w-full">
          <!-- <div class="vx-col hidden sm:hidden md:hidden lg:block lg:w-1/2 mx-auto self-center">
              <img src="@/assets/images/pages/login.png" alt="login" class="mx-auto" />
            </div> -->
          <div class="w-full">
            <div class="px-8 py-6">
              <div class="vx-card__title mb-8">
                <h4 class="mb-4 text-center">Recover your password</h4>
                <p class="text-center"> Please enter your email address and we'll send you instructions on how to reset your password. </p>
              </div>
              <div class="w-full mb-6">
                <vs-input
                  v-model="email"
                  v-validate="'required|email|min:3'"
                  data-vv-validate-on="blur"
                  name="email"
                  label-placeholder="Email"
                  class="w-full"
                />
                <span class="text-danger text-sm">{{ errors.first('email') }}</span>
              </div>

              <!-- <vs-button type="border" to="/pages/login" class="px-4 w-full md:w-auto">Back To Login</vs-button> -->
              <vs-button :disabled="email === '' || errors.any()" class="px-4 w-full mt-3 mb-8 md:mt-0 md:mb-0" @click="resetPassword()"
                >Reset Password</vs-button
              >
              <p class="text-sm mt-3 text-center"
                >I am not supposed to be here.
                <span class="text-rag-green-dark cursor-pointer hover:underline" @click="toLogin">To login</span></p
              >
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      email: '',
      emailCheck: false,
    };
  },
  methods: {
    toLogin() {
      this.$router.push('/pages/login');
    },
    async resetPassword() {
      const isValid = await this.$validator.validate();
      if (!isValid) return;
      const payload = {
        email: this.email,
        notify: this.$vs.notify,
      };
      this.$store.dispatch('auth/forgotPassword', payload).then(() => {
        this.$router.push('/pages/login');
      });
    },
  },
};
</script>
